/* :root {
  --primary-color: #2789FF;
  --secondary-color: rgb(255, 255, 255);
  --trinary-color: #3b90f8;
} */
*{
  -ms-overflow-style: none;
}
::-webkit-scrollbar {
  display: none;
}

:root {
  --primary-color: #1482C5;
  --secondary-color: #FFFF;
  --trinary-color: #0d6eaa;
}


html {
  background-color: rgba(236, 236, 236, 0.8);
}
.App {
  background-color: rgba(236, 236, 236, 0.8);
  min-height: 60vh;
}
button:focus {outline:0;}

.appBarLink {
  margin-top: 3px;
  color: var(--secondary-color) !important;
  text-decoration: none;
}
.appBarLink:hover {
  color: var(--secondary-color) !important;
  filter: brightness(80%);
  text-decoration: none;
}
.vl-primary-text-color {
  color: var(--primary-color) !important;
}

.vl-primary-color {
  color: var(--primary-color) !important;
  background-color: var(--primary-color) !important;
}

.homeTile {
  width: 105px;
  height: 105px;
  position: relative;
}

.homeTile:hover {
  cursor: pointer;
  filter: brightness(.9);
  transform: translateX(3px);
  transform: translateY(-3px);
}

.absoluteCenter {
  position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}

.loading-circle {
    color: var(--primary-color) !important;
}

.login-container {
  height: 100vh;
  position: relative;
}

.vertical-center {
  width: 95%;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.text-center {
  text-align: center;
}
.topMenuButton {
  background-color: #e5e5e5 !important;
  color: #888 !important;
  font-size: 10px !important;
  padding: 0 7px !important;
  height: 30px;
}

.topMenuButton:hover {
  background-color: #c7c7c7cb !important;
}
/* .primaryVLButton {
  color: white !important;
  background-color: var(--primary-color) !important;
}
.primaryVLButton:hover {
  color: white !important;
  background-color: var(--primary-color) !important;
  filter: brightness(0.9);
} */

.secondaryVLButton {
  color: white !important;
  background-color: #545454 !important;
}

.secondaryVLButton:hover {
  color: white !important;
  background-color: #363636 !important;
}

.vendorEdit {
  margin-left: 0.25rem;
  font-size: 12px;
  color: rgba(0,0,255, 0.8);
}

.vendorEdit:hover {
  cursor: pointer;
  color: rgba(0,0,255, 1)
}

.editLink {
  color: rgba(0,0,255, 0.7);
}

.editLink:hover {
  cursor: pointer;
  color: rgba(0,0,255, 1);
  text-decoration: underline;
}


.mainTabNav {
  font-size: 1.3rem;
  background-color: #E86613; 
  color: white;
  border-radius: 5px;
  height: 40px;
  margin: auto;
}

.mainTabActive {
  margin-top: -5px;
  color: #E86613;
  height: 45px;
  background-color: white;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border: solid 1px grey;
  border-bottom-color: white;
}

.nav-item:hover {
  cursor: pointer;
  background-color: rgb(201, 88, 18);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.mainNavTab {
  color: white !important;
  text-decoration: none !important;
}

.top-list-item {
  font-weight: normal;
  line-height: 25px;
  display: block;
  float: left;
  padding: 0 7px;
  height: 25px;
  background: #e5e5e5;
  color: #888;
}

.top-list-item:hover {
  background: #d6d6d6;
}

.topNavLink:hover {
  cursor: pointer;
}

.first-list-item {
border-bottom-left-radius: 5px ;
}

.last-list-item {
  border-bottom-right-radius: 5px;
}

.customNav {
  display: flex;
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.customNav .navItem {
  padding: 8px 4px;
  margin-top: -8px;
}

.customNav .navItem .navText {
  padding: 8px;
  
}
